// nav {
//   position: fixed;
//   height: 100px;
//   transition: height 0.3s ease-in-out;
  
// }

// nav.shrink {
//   height: 50px;
// }

.header-navbar {
  // background-color: #060b26;
  // height: 100px;
  background-image: url("../assets/NFT.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // z-index: 1;
  // padding: 0px 0px;
  // background-color: rgba(145, 79, 79, 0.337); /* Transparent Yellow */
  // background-color: #ffffff;
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  // -webkit-box-shadow: 0 8px 6px -6px black;
  //  -moz-box-shadow: 0 8px 6px -6px black;
  //       box-shadow: 0 8px 6px -6px black;
  scroll-behavior: smooth;
  // -webkit-box-shadow: 0px 3px 5px 0px rgb(157, 60, 60);
  // -moz-box-shadow: 0px 3px 5px 0px rgb(179, 63, 63);
  // box-shadow: 0px 3px 5px 0px rgb(184, 57, 57);

  svg {
    width: 20px;
  }

  .menu-header {

    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // margin-right: 20px;
    // text-align: center;
    img {
      background-size: cover;
      background-position: center;
      width: 80px;
      height: 80px;
      animation: rotation 8s infinite linear;

    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(359deg);
      }
    }

    .text {
      color: rgb(255, 255, 255);
      font-size: 1.5rem;

      p {
        // margin-right: 30px;
        padding: 10px 0px;
        font-family: 'Sansita Swashed', cursive;
        z-index: 100000;
        color: #ffffff;
      }
    }

    .text-react {
      color: rgb(255, 255, 255);
      padding: 5px 0px;
      font-size: 1.1rem;
      font-style: italic;

      p {
        font-family: 'Sansita Swashed', cursive;
        z-index: 100000;
      }
    }
  }
}
