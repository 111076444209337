.hover-text-container {
  // position: 'absolute';
  background-color: white !important;
  padding: 5px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: 700;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}