.home-v2-container {
  margin-top: 150px;
  display: flex !important;
  .home-v2-section {
    margin: 0px 20px;
  }

  .home-v2-content {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
    }

    .home-v2-logo {

      padding: 0px 30px;

      img {}
    }
  }
}

.home-content {
  font-weight: 500;

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.home-content__img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 700px) {
  .home-content {
    h2 {
      font-size: 10px;
    }
  }
}