

.footer {
  // background: #ae0404db;
  margin-top: 50px;
  padding: 0px 20px !important;
  color:rgb(0, 0, 0);
  .footer__container {
    // padding: 60px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // grid-template-columns: 1fr 2fr;
    
    .footer__left {
      // display: flex;
      // flex-direction: column;
      // font-family: 'Sansita Swashed', cursive;
      // margin: 10px;
      color: #000000;
      // background: #455d7a; 
// text-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #FF2D95 0px 0px 20px, #FF2D95 0px 0px 30px, #FF2D95 0px 0px 40px, #FF2D95 0px 0px 50px, #FF2D95 0px 0px 75px;
      h2 {
        // margin-bottom: 24px;
        letter-spacing: 0.5em;
        font-size: 0.7em;
        font-family: "Roboto Slab", serif;
        font-optical-sizing: auto;
        // font-weight: <weight>;
        font-style: normal;
        
        
      }
      p {
        letter-spacing: 0.2em;
      }
    }
    .footer__right {
      display: flex;
      align-items: left;
      // justify-content: space-between;
      // padding:  0 100px;
      // padding: 50px 0px;
      width: 100%;

      .about-social-animation {
        padding-right:10px;
        margin: 0;
      }
      
      a {
        color: rgb(0, 0, 0);
        text-decoration: none;
        font-weight: 700;
        text-transform: uppercase;
        // padding: 0px 10px;
        margin: 10px;
      }
      
    }
  }
}

.about-social-links {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  
  padding: 10px;
  margin: 2%;
}

.about-social-animation {
  // animation: rotation 8s infinite linear;
  
  a {
    color:rgb(0, 0, 0);
    font-size: 1em;
    width: 100%;
    margin: 5px;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

// @media screen and (max-width: 768px) {
//   .footer {
//     .footer__container {
//       padding: 60px 24px;
//       grid-template-columns: 1fr 1fr;
//       .footer__left {
//       }
//       .footer__right {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-content: center;
//         align-items: center;
//         height: 100%;
//         width: 100%;
//         padding: 0;
//         margin: 0;
//         .about-social-animation {
//           padding: 0;
//           margin: 0;
//           width: 50px;
//         }

//       }
//     }
//   }
// }

// @media screen and (max-width: 468px) {
//   .footer {
//     .footer__container {
//       .footer__left {
//       }
//       .footer__right {
//         padding: 0 16px;
//       }
//     }
//   }
// }