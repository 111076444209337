@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

// *
// {
// 	margin: 0;
// 	padding: 0;
// 	font-family: 'Poppins', sans-serif;
// }
.skill_application {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // min-height: 100vh;
  // background: #16384c;
}

.container {
  position: relative;
  width: 1100px;
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-wrap: wrap;
  padding: 30px 20px 10px;
}

.container .card {
  max-width: 300px;
  height: 200px;
  background: #fff;
  // margin: 30px 10px;
  padding: 20px 15px;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  // box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
}

.container .card:hover {
  // height: 420px;
}

.container .card .imgBx {
  // position: relative;
  // background: #fff;
  // top: -100px;
  // left: 20px;
  min-width: initial;
  // width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 260px;
  border-radius: 4px;
  z-index: 1;
  margin: 0 !important;
  // box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.container .card .imgBx img {
  max-width: 100px;
  border-radius: 4px;
}

.container .card .content {
  position: relative;
  // margin-top: -140px;
  padding: 10px 15px;
  text-align: center;
  color: #111;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
  transition-delay: 0s;
}

.container .card:hover .content {
  visibility: visible;
  opacity: 1;
  margin-top: -40px;
  transition-delay: 0.3s;
}