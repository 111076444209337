.mobile {
  // background-color: red;
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  // height: 100vh;
  height: 100%;
  // padding-top: 200px;
  padding-bottom: 200px;
  // padding-left: 60px;
  
  align-items: center;
  // height: 100%;
  .mobile__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 0px;
    // width: 40%;
    // background-color: rgb(254, 248, 248);
    // background-color: #333;
    // width: 100%;
    // padding: 30px  0px;
    // width: 100%;
    .mobile__logo {
      padding:20px;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 50%;
      img {
        // object-fit: contain;
        width:300px;
      }
    }
    .mobile__exp {
      padding: 30px 50px;
      width: 100%;
      
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      line-height: 2em;
      color: rgb(255, 255, 255);
      // height: 100%;
      // padding: 0px;
      p {
        font-size: calc(1vw + 2rem);
        padding: 60px 0px;
        // font-size: clamp(1.2em, 1em, 1em);
        // width: 40%;
      }
    }
  }
  .mobile__project {
    display: flex;
    flex-wrap: wrap;
    // width: 60%;
    justify-content: center;
    align-items: center;
    // margin: 0px 400px;
    // width: 80%;
    // padding: 30px  0px;
    a {
      text-decoration: none;  
      color: rgb(255, 255, 255);
    }
    iframe {
      width: 200px;
      height: 300px;
      padding: 20px;
      margin: 10px 0px;
      // border-radius: 15%;
    }
    
    // .mobile__project__item {
      //   width: 80%;
      //   height: 80%;
      // }
      .mobile__items__container {
        margin: 10px;
      // background-color: #10316b;
      // border-radius: 30%;
        overflow: hidden;

        .mobile__url__container {
          display: flex;
          justify-content: center;
          align-items: center;
          
          p {
            font-size: 20px;
          }
          
        }
        .mobile__image__container {
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 200px;
          // height: 200px;
          overflow: hidden;
          .mobile__image__self {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            width: 100%;
            margin: 20px 0px;
              object-fit: contain;

            // height: 100%;
            video {
              height: 300px;
              object-fit: cover;
              // width: 50%;
              // height: 100%;
              background-repeat: no-repeat;
            }
          }

          &:hover {
            // background-color: lightgrey;
            // padding-bottom: 30px;
          }
        } 
        .mobile__desc__container {
          .mobile__desc__items {
            .mobile__desc__items__line {

            }
          }
        }
    }
  }
}